var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:".container"},[_c('Header'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"spaceFinal"}),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"banner"},[_c('div',[_c('p',[_vm._v("Branding")])]),_c('h1',[_vm._v(" Sweat "),_c('br',{staticClass:"only-mov"}),_vm._v(" & "),_c('br',{staticClass:"only-pc"}),_vm._v("Wear ")]),_c('p',[_vm._v(" Extensión de formulario para clientes "),_c('br',{staticClass:"only-mov"}),_vm._v(" potenciales "),_c('br',{staticClass:"only-pc"}),_vm._v(" en Google Ads: Búsqueda,"),_c('br',{staticClass:"only-mov"}),_vm._v(" Display, Youtube y "),_c('br',{staticClass:"only-pc"}),_vm._v("Discovery ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"parallax"},[_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',[_c('p',[_vm._v("El reto")]),_c('h2',[_vm._v(" Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery ")]),_c('p',{staticClass:"only-mov"},[_vm._v(" ¿Saben lo que se me hizo más difícil? Quedarme encerrada en casa, y creo que no se van a oponer, pero para el primer mes de confinamiento ya no hallábamos qué hacer con nuestras vidas. Y es por eso que me terminé haciendo adicta a las aplicaciones… y no se hagan, ustedes también. "),_c('br'),_c('br'),_vm._v(" No los culpo, de hecho se transformaron en una especie de “alivio” durante estos meses difíciles. ")])]),_c('div',[_c('picture',{staticClass:"spcelyr"},[_c('source',{attrs:{"srcset":require("../assets/landingsBrandingPortafolios/logoSweat.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/logoSweat.png"),"alt":"Logo Sweat & Wear","loading":"lazy"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('picture',{staticClass:"beforePalette only-pc"},[_c('source',{attrs:{"srcset":require("../assets/landingsBrandingPortafolios/sweat2.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/sweat2.png"),"alt":"Imagen Decorativa"}})]),_c('picture',{staticClass:"beforePalette only-mov"},[_c('source',{attrs:{"srcset":require("../assets/landingsBrandingPortafolios/sweatmv1.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/sweatmv1.png"),"alt":"Imagen Decorativa"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pallete"},[_c('h2',[_vm._v("Color Palette")]),_c('div',[_c('p',[_vm._v("ALMOND")]),_c('h3',[_vm._v("#E8DABD")])]),_c('div',[_c('p',[_vm._v("CINNABAR")]),_c('h3',[_vm._v("#E04403")])]),_c('div',[_c('p',[_vm._v("FERN GREEN")]),_c('h3',[_vm._v("#40683C")])]),_c('div',[_c('p',[_vm._v("EERIE BLACK")]),_c('h3',[_vm._v("#1D1D1D")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mosaico__video"},[_c('picture',{staticClass:"only-pc"},[_c('source',{attrs:{"srcset":require("../assets/landingsBrandingPortafolios/sweat3.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/sweat3.png"),"alt":"Imagen de Sweat & Wear","loading":"lazy"}})]),_c('picture',{staticClass:"only-mov"},[_c('source',{attrs:{"srcset":"","type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/sweatmv2.png"),"alt":"Imagen de Sweat & Wear","loading":"lazy"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mosaico"},[_c('div',{staticClass:"video__uno"},[_c('picture',[_c('source',{attrs:{"srcset":require("../assets/landingsBrandingPortafolios/sweat4.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/sweat4.png"),"alt":"Imagen de Sweat & Wear","loading":"lazy"}})])]),_c('div',{staticClass:"mosaico__stack"},[_c('picture',[_c('source',{attrs:{"srcset":require("../assets/landingsBrandingPortafolios/sweat5.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../assets/landingsBrandingPortafolios/sweat5.png"),"alt":"Imagen de Sweat & Wear","loading":"lazy"}})])])])
}]

export { render, staticRenderFns }